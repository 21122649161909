body {
  font-family: Arial, sans-serif;
  background-color: #f4f4f4;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 100vh;
}

.container {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding-top: 20px;
  padding-bottom: 20px;
}

.cart-container {
  background-color: #fff;
  padding-top: 20px;
  padding-bottom: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  min-width: 85vw;
  box-sizing: border-box;
}


@media (min-width: 768px) {
  .container,
  .cart-container {
    /* For medium to large screens */
    min-width: 700px;
    /* Maximum width for larger screens */
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }

}

@media (min-width: 1024px) {

  .container,
  .cart-container {
    /* For larger screens */
    min-width: 900px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
}

ul {
  list-style-type: none;
  padding: 0;
}

h2 {
  text-align: center;
  color: #333;
}

form {
  display: flex;
  flex-direction: column;
}

label {
  margin-bottom: 5px;
  color: #666;
}

input {
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;
  /* Ensure padding and border are included in the width */
}

input[type="number"] {
  max-width: 75px;
  min-width: 50px;
}

button {
  padding: 10px;
  margin-inline: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button.navbar-toggler {
  margin: 0;
}

button.loginButton {
  width: 100%;
  margin: 0px;
  margin-top: 15px;
}

p {
  text-align: center;
  color: #333;
}

.cart-count-container {
  position: relative;
  display: inline-block;
}

.cart-count {
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 2px 6px;
  font-size: 12px;
  position: absolute;
  top: 25px;
  right: -10px;
}

.cart-item {
  margin: 10px;
}

.quantity-input {
  text-align: center;
}

@keyframes fadeOut {

  0%,
  25% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.notification-popup {
  position: fixed;
  top: 90%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  animation: fadeOut 3s forwards;
}

label {
  padding-right: 10px;
}

.combo-box {
  min-width: 120px;
}

/* Hide spinner for Chrome, Safari, Edge, Opera */
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Hide spinner for Firefox */
input[type=number] {
  -moz-appearance: textfield;
}