/* General styles */
body,
#root {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
}

/* Navbar styles */
.navbar {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
}

.navbar-nav {
  flex-direction: column;
}

.navbar-nav .nav-item {
  margin-left: 10px;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-menu {
  display: none;
}

/* Remove gap so it looks connected */
.nav-item.dropdown:hover .dropdown-menu {
  display: block;
  margin-top: 0;
}

.navbar-toggler {
  border: none;
}

.navbar-toggler:focus {
  outline: none;
}

.nav-item .dropdown-menu.show {
  display: block;
}

.main-content {
  padding-top: 4rem;
  /* Adjust this value if your navbar height changes */
  /*text-align: center;*/
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.subcontainer {
  border: 1px solid #e9ecef;
  border-radius: 0.5rem;
  margin-top: 1rem;
  padding: 1rem;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* Profile button styles for larger screens */
.Profile-Button {
  border-radius: 0;
}

.btn2 {
  display: flex;
  width: 100%;
  margin-top: 0.4rem;
  margin-bottom: 0.4rem;
  margin-inline: 0;
  height: calc(1.5em + .75rem + 2px);
}

.btn-primary:disabled {
  opacity: 0.35;
  filter: grayscale(90%);
  cursor: not-allowed;
}

@media print {

  .print-button,
  .back-button,
  .navbar,
  .footer {
    display: none !important;
  }

  .order-detail-container {
    margin: 0;
    padding: 0;
  }

  h2,
  h3 {
    margin-top: 20px;
    margin-bottom: 10px;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }

  th,
  td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }

  thead {
    display: table-header-group;
  }

  tfoot {
    display: table-footer-group;
  }

  tr {
    page-break-inside: avoid;
  }

  .table-striped tbody tr:nth-of-type(odd) {
    background-color: #f9f9f9;
  }

  .table-active {
    background-color: #e9ecef !important;
  }
}


.cart-item {
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  padding: 5px 0;
  border-bottom: 1px solid #eee;
}

.cart-item-checkout {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  padding: 5px 0;
  border-bottom: 1px solid #eee;
}

.cart-item span:first-child {
  flex-grow: 1;
  margin-right: 10px;
  text-align: left;
}

.cart-item span:last-child {
  white-space: nowrap;
}

.cart-summary {
  background-color: #f8f9fa;
  border-radius: 5px;
  padding: 15px;
  margin-bottom: 20px;
}

.about {
  text-align: justify;
  text-justify: inter-word;
}

.min-vh-100 {
  min-height: 100vh;
}

.d-flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.flex-grow-1 {
  flex-grow: 1;
}

footer {
  flex-shrink: 0;
}

.privacy-policy-content {
  line-height: 1.4;
}

.privacy-policy-content p,
.privacy-policy-content ul {
  margin-bottom: 0.75rem;
}

.privacy-policy-list .list-group-item {
  border: none;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.privacy-policy-list .list-group-item:hover {
  text-decoration: none;
  background-color: transparent;
}

.terms-of-service-content {
  line-height: 1.4;
}

.terms-of-service-content p,
.terms-of-service-content ul {
  margin-bottom: 0.75rem;
}

.terms-of-service-list .list-group-item {
  border: none;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.terms-of-service-list .list-group-item:hover {
  text-decoration: none;
  background-color: transparent;
}

.grecaptcha-badge {
  visibility: hidden;
}

.terms-of-service-content {
  max-width: 100%;
}